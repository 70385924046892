import { helpers } from "vuelidate/lib/validators";
import { imageSize as getImageSize } from "../helpers/images";
import { mergeTimeRanges } from "../helpers/functions";

import { IMAGE_RESTRICTIONS } from "../config";

import { ImageTypes } from "@/@types/common";
import { Day } from "@/@types/model";
import { ImageSize } from "@/@types/common";

/* For vuelidate  */
export const mimetype = (types: string[], labels: string[]) =>
  helpers.withParams({ type: "mimetype", value: { types, labels } }, (value: File) => {
    if (!helpers.req(value)) return false;
    return types.includes(value.type);
  });

export const fileSize = (size: number) =>
  helpers.withParams(
    { type: "fileSize", value: size },
    (value: File) => !helpers.req(value) || value.size / 1000 <= size,
  );

export const USERNAME_MIN_LENGTH = 6;
export const USERNAME_MAX_LENGTH = 30;
export const username = (value: string) =>
  new RegExp(
    `^(?=[a-z0-9._@]{${USERNAME_MIN_LENGTH},${USERNAME_MAX_LENGTH}}$)(?!.*[_.]{2})[^_.].*[^_.]$`,
  ).test(value);

/* eslint-disable no-control-regex */
const EMAIL_REGEX =
  /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
export const email = (value: string) =>
  !helpers.req(value) || EMAIL_REGEX.test(value.toLowerCase());

export const PASSWORD_MAX_LENGTH = 8;
export const SUBDOMAIN_MAX_LENGTH = 50;
const SUPDOMAIN_BAD_WORDS = [
  "admin",
  "administrator",
  "administrador",
  "administradora",
  "spravce",
  "podpora",
  "supplier",
  "help",
  "helpdesk",
  "test",
  "testtest",
  "test2",
  "support",
  "mail",
  "order",
  "fuck",
  "scam",
  "menu",
  "mymenu",
  "obed",
  "restaurace",
  "pub",
  "bar",
  "hospoda",
  "restaurant",
];
export const subdomain = (value: string) =>
  new RegExp(`^(?=[a-zA-Z0-9.-]{3,${SUBDOMAIN_MAX_LENGTH}}$)(?!.*[-.]{2})[^-.].*[^-.]$`).test(
    value,
  );
export const badSubdomain = (value: string) => !SUPDOMAIN_BAD_WORDS.includes(value);

/* For timeWindows */

// Merge Time Overlapping
export const mergeTimeWindows = (day: Day) => ({
  ...day,
  timeWindows: mergeTimeRanges(
    day.timeWindows.map((timeWindow) => [timeWindow.start, timeWindow.end]),
  ).map(([start, end]) => ({ start, end })),
});

/* For Image Validator */

export const image = async (image: File, imageType: ImageTypes) => {
  const size = await getImageSize(image);

  const errors = [];
  const validators = [imageSize(size, imageType)];

  for await (const error of validators) {
    if (error !== true) errors.push(error);
  }

  return errors;
};

const imageSize = async (size: ImageSize, imageType: ImageTypes) => {
  const boundary = IMAGE_RESTRICTIONS[imageType] || IMAGE_RESTRICTIONS["IMAGE"];

  const isValid =
    size.width >= boundary.min.width &&
    size.width <= boundary.max.width &&
    size.height >= boundary.min.height &&
    size.height <= boundary.max.height;

  if (!isValid) {
    return `Image size is out of range! <br /> <small>Minimum size: ${boundary.min.width}x${boundary.min.height} <br />
    Maximum size: ${boundary.max.width}x${boundary.max.height} <br /> Current size: ${size.width}x${size.height}</small>`;
  }

  return true;
};
