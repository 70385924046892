<template>
  <div id="login">
    <v-card class="login-card" elevation="2">
      <v-toolbar class="login-card__toolbar" flat>
        <v-toolbar-title> {{ $t("Auth.Authorization") }} </v-toolbar-title>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <form class="login-form" @submit.prevent="login">
          <v-text-field
            v-model.trim="email"
            prepend-icon="mdi-email"
            :label="$t('Auth.Email')"
            :error-messages="emailErrors"
          ></v-text-field>

          <v-text-field
            v-model.trim="password"
            prepend-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('Auth.Password')"
            :error-messages="passwordErrors"
          >
            <template v-slot:append-outer>
              <v-btn tabindex="-1" @click="showPassword = !showPassword" text small>
                {{ !showPassword ? $t("GlobalUI.Show") : $t("GlobalUI.Hide") }}
              </v-btn>
            </template>
          </v-text-field>

          <v-checkbox
            class="mt-0 mb-6"
            v-model="remember"
            :label="$t('Auth.RememberMe')"
            hide-details
          />

          <v-btn type="submit" color="primary" :loading="isBusy" block>
            {{ $t("Auth.SignIn") }}
          </v-btn>
        </form>

        <v-alert
          v-if="errorCode"
          class="mt-5"
          text
          outlined
          color="deep-orange"
          icon="mdi-fire"
          dismissible
        >
          {{ $t(`ServerResponse.${errorCode}`) }}
        </v-alert>

        <v-alert v-if="isTokenExpired === 'true'" class="mt-5" color="deep-orange" text outlined>
          <h3 class="text-h6 mb-3">{{ $t("Auth.SessionExpired") }}</h3>
          {{ $t("Auth.LogoutFromAdminPanelSuccess") }}
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="d-flex flex-column flex-sm-row justify-center align-center mt-4">
      <span>{{ $t("Auth.DoNothaveAnAccount") }}</span>
      <router-link :to="{ name: 'register' }" class="ml-0 mt-2 ml-sm-4 mt-sm-0">
        <b>{{ $t("Auth.SignUp") }}</b>
      </router-link>
    </div>
    <div class="d-flex justify-center align-center mt-4">
      <router-link :to="{ name: 'password.request' }">
        {{ $t("Auth.ForgotYourPassword") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { email } from "../../services/validators.service";
import login from "../../mixins/login";

export default {
  name: "Login",

  middleware: "guest",

  layout: "auth",

  metaInfo() {
    return { title: "Login" };
  },

  mixins: [validationMixin, login],

  validations: {
    email: { required, email },
    password: { required },
  },

  props: {
    isTokenExpired: {
      type: String,
      default: "false",
    },
  },

  data: () => ({
    showPassword: false,
  }),

  computed: {
    ...mapGetters({
      check: "auth/check",
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push(this.$t("Validation.MailRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("Validation.FieldRequired"));
      return errors;
    },
  },

  created() {
    if (this.check) this.$store.dispatch("auth/clearUser");
  },
};
</script>
