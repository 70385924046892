type Range = [string, string];
export const mergeTimeRanges = (ranges: Range[]) => {
  if (!(ranges && ranges.length)) {
    return [];
  }

  // Stack of final ranges
  const stack: Range[] = [];

  // Sort according to start value
  ranges.sort(function (a, b) {
    return a[0].localeCompare(b[0]);
  });

  // Add first range to stack
  stack.push(ranges[0]);

  ranges.slice(1).forEach(function (range) {
    const top = stack[stack.length - 1];

    if (top[1] < range[0]) {
      // No overlap, push range onto stack
      stack.push(range);
    } else if (top[1] < range[1]) {
      // Update previous range
      top[1] = range[1];
    }
  });

  return stack;
};

export const nonReactive = <T = any>(payload: T): T => JSON.parse(JSON.stringify(payload));
